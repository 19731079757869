// this fetch module is required to avoid "Invariant Violation" in Gatsby at build time
import "isomorphic-fetch"

import { GraphQLClient } from "graphql-hooks";
const client = new GraphQLClient({
  // eslint-disable-next-line no-undef
  url: process.env.GATSBY_WPGRAPHQL_ENDPOINT,
});

export default client
