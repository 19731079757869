exports.components = {
  "component---src-create-pages-book-js": () => import("./../../../src/create-pages/book.js" /* webpackChunkName: "component---src-create-pages-book-js" */),
  "component---src-create-pages-policy-theme-js": () => import("./../../../src/create-pages/policy-theme.js" /* webpackChunkName: "component---src-create-pages-policy-theme-js" */),
  "component---src-create-pages-research-theme-js": () => import("./../../../src/create-pages/research-theme.js" /* webpackChunkName: "component---src-create-pages-research-theme-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-institute-for-innovation-and-public-purpose-js": () => import("./../../../src/pages/about/institute-for-innovation-and-public-purpose.js" /* webpackChunkName: "component---src-pages-about-institute-for-innovation-and-public-purpose-js" */),
  "component---src-pages-about-press-js": () => import("./../../../src/pages/about/press.js" /* webpackChunkName: "component---src-pages-about-press-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-articles-about-mariana-js": () => import("./../../../src/pages/media/articles-about-mariana.js" /* webpackChunkName: "component---src-pages-media-articles-about-mariana-js" */),
  "component---src-pages-media-articles-by-mariana-js": () => import("./../../../src/pages/media/articles-by-mariana.js" /* webpackChunkName: "component---src-pages-media-articles-by-mariana-js" */),
  "component---src-pages-media-blogs-js": () => import("./../../../src/pages/media/blogs.js" /* webpackChunkName: "component---src-pages-media-blogs-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-media-radio-and-podcasts-js": () => import("./../../../src/pages/media/radio-and-podcasts.js" /* webpackChunkName: "component---src-pages-media-radio-and-podcasts-js" */),
  "component---src-pages-media-tv-js": () => import("./../../../src/pages/media/tv.js" /* webpackChunkName: "component---src-pages-media-tv-js" */),
  "component---src-pages-mission-economy-launch-events-index-js": () => import("./../../../src/pages/mission-economy-launch-events/index.js" /* webpackChunkName: "component---src-pages-mission-economy-launch-events-index-js" */),
  "component---src-pages-policy-impact-js": () => import("./../../../src/pages/policy/impact.js" /* webpackChunkName: "component---src-pages-policy-impact-js" */),
  "component---src-pages-policy-index-js": () => import("./../../../src/pages/policy/index.js" /* webpackChunkName: "component---src-pages-policy-index-js" */),
  "component---src-pages-policy-policy-papers-js": () => import("./../../../src/pages/policy/policy-papers.js" /* webpackChunkName: "component---src-pages-policy-policy-papers-js" */),
  "component---src-pages-policy-stories-js": () => import("./../../../src/pages/policy/stories.js" /* webpackChunkName: "component---src-pages-policy-stories-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-research-chapters-js": () => import("./../../../src/pages/research/chapters.js" /* webpackChunkName: "component---src-pages-research-chapters-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-journal-articles-js": () => import("./../../../src/pages/research/journal-articles.js" /* webpackChunkName: "component---src-pages-research-journal-articles-js" */),
  "component---src-pages-research-publications-js": () => import("./../../../src/pages/research/publications.js" /* webpackChunkName: "component---src-pages-research-publications-js" */),
  "component---src-pages-research-themes-js": () => import("./../../../src/pages/research/themes.js" /* webpackChunkName: "component---src-pages-research-themes-js" */),
  "component---src-pages-research-working-papers-js": () => import("./../../../src/pages/research/working-papers.js" /* webpackChunkName: "component---src-pages-research-working-papers-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-talks-index-js": () => import("./../../../src/pages/talks/index.js" /* webpackChunkName: "component---src-pages-talks-index-js" */),
  "component---src-pages-talks-past-js": () => import("./../../../src/pages/talks/past.js" /* webpackChunkName: "component---src-pages-talks-past-js" */)
}

